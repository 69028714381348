import { useState } from "react";
import { Account } from "providers/accounts";
import { DomiBalance } from "components/common/DomiBalance";
import { TableCardBody } from "components/common/TableCardBody";
import { Address } from "components/common/Address";
import { addressLabel } from "utils/tx";
import { useCluster } from "providers/cluster";
import { useTokenRegistry } from "providers/mints/token-registry";
import { displayTimestampUtc } from "utils/date";
import { Connection } from "@solana/web3.js";
import { Slot } from "components/common/Slot";

const CHECKOUT_SERVER_URL = process.env.REACT_APP_CHECKOUT_SERVER_URL;

export function UnknownAccountCard({ account }: { account: Account }) {
  const { cluster, url } = useCluster();
  const { tokenRegistry } = useTokenRegistry();

  const [blockTime, setBlockTime] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);
  const [latestTx, setLatestTx] = useState("");
  const [historyJson, setHistoryJson] = useState(null);

  const label = addressLabel(account.pubkey.toBase58(), cluster, tokenRegistry);

  const handleVerifyHistory = () => {
    let asyncTask = async () => {
      const latestResponse = await fetch(`${CHECKOUT_SERVER_URL}/btc_transaction/latest`);
      const newLatestTx = await latestResponse.text();

      const verifyResponse = await fetch(`${CHECKOUT_SERVER_URL}/btc_transaction/${newLatestTx}/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "domi_account": account.pubkey.toString() }),
      });
      const verifyResponseJson = await verifyResponse.json();

      const connection = new Connection(url, "confirmed");
      const block = await connection.getBlock(verifyResponseJson["slot_number"], {
        maxSupportedTransactionVersion: 0,
      });

      setBlockTime(block?.blockTime || null);
      setLatestTx(newLatestTx);
      setHistoryJson(verifyResponseJson);
    };
    setLoading(true);
    asyncTask().catch(console.error).finally(() => setLoading(false));
  };

  return (
    <div className="card">
      <div className="card-header align-items-center">
        <h3 className="card-header-title">Overview</h3>
      </div>

      <TableCardBody>
        <tr>
          <td>Address</td>
          <td className="text-lg-end">
            <Address pubkey={account.pubkey} alignRight raw />
          </td>
        </tr>
        {label && (
          <tr>
            <td>Address Label</td>
            <td className="text-lg-end">{label}</td>
          </tr>
        )}
        <tr>
          <td>Balance (DOMI)</td>
          <td className="text-lg-end">
            {account.satomis === 0 ? (
              "Account does not exist"
            ) : (
              <DomiBalance satomis={account.satomis} />
            )}
          </td>
        </tr>

        {account.space !== undefined && (
          <tr>
            <td>Allocated Data Size</td>
            <td className="text-lg-end">{account.space} byte(s)</td>
          </tr>
        )}

        <tr>
          <td>Assigned Program Id</td>
          <td className="text-lg-end">
            <Address pubkey={account.owner} alignRight link />
          </td>
        </tr>

        <tr>
          <td>Executable</td>
          <td className="text-lg-end">{account.executable ? "Yes" : "No"}</td>
        </tr>

        {historyJson === null ? (
          <tr>
            <td>Verified History</td>
            <td className="text-lg-end">
              <button
                className="btn btn-white btn-sm"
                onClick={handleVerifyHistory}
                disabled={loading}
              >
                <span className="fe fe-refresh-cw me-2"></span>
                {loading ? "Loading..." : "Verify account history on Bitcoin"}
              </button>
            </td>
          </tr>
        ) : (
          <>
            <tr>
              <td>Bitcoin transaction hash</td>
              <td className="text-lg-end">
                <a href={`https://mempool.space/tx/${latestTx}`} target="_blank" rel="noopener noreferrer">
                  <div>{latestTx}</div>
                </a>
              </td>
            </tr>

            <tr>
              <td>Domichain block number</td>
              <td className="text-lg-end w-1">
                <Slot slot={historyJson["slot_number"]} link />
              </td>
            </tr>

            <tr>
              <td>Domichain block timestamp</td>
              <td className="text-muted text-lg-end">
                {blockTime
                  ? displayTimestampUtc(blockTime * 1000, true)
                  : "---"}
              </td>
            </tr>

            <tr>
              <td>Domichain block hash</td>
              <td className="text-lg-end">
                {historyJson["block_hash"]}
              </td>
            </tr>

            <tr>
              <td>Domichain bank hash</td>
              <td className="text-lg-end">
                {historyJson["bank_hash"]}
              </td>
            </tr>

            <tr>
              <td>Verified account balance (DOMI)</td>
              <td className="text-lg-end">
                {historyJson["account_balance"] === 0 ? (
                  "Account does not exist"
                ) : (
                  <DomiBalance satomis={historyJson["account_balance"]} />
                )}
              </td>
            </tr>
          </>
        )}
      </TableCardBody>
    </div>
  );
}
